var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { staticClass: "text-center", attrs: { href: _vm.link } }, [
    _c("div", { staticClass: "flex justify-center" }, [
      _vm.days > 0
        ? _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "digit" }, [_vm._v(_vm._s(_vm.days))]),
            _vm._v(" "),
            _c("div", { staticClass: "format" }, [_vm._v("Days")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "digit" }, [_vm._v(_vm._s(_vm.hours))]),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Hours")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "digit" }, [_vm._v(_vm._s(_vm.minutes))]),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Minutes")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "digit" }, [_vm._v(_vm._s(_vm.seconds))]),
        _vm._v(" "),
        _c("div", { staticClass: "format" }, [_vm._v("Seconds")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }