<template>
  <a
    :href="link"
    class="text-center"
  >
    <div class="flex justify-center">
      <div
        v-if="days > 0"
        class="item"
      >
        <div class="digit">{{ days }}</div>
        <div class="format">Days</div>
      </div>

      <div class="item">
        <div class="digit">{{ hours }}</div>
        <div class="format">Hours</div>
      </div>

      <div class="item">
        <div class="digit">{{ minutes }}</div>
        <div class="format">Minutes</div>
      </div>

      <div class="item">
        <div class="digit">{{ seconds }}</div>
        <div class="format">Seconds</div>
      </div>
    </div>

    <div class="label">{{ label }}</div>
  </a>
</template>

<script>
import banner from 'images/header-banner.jpg';

export default {
  props: {
    link: String,
    label: String,
    date: Number
  },

  data() {
    return {
      image: banner,
      interval: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0
    };
  },

  mounted() {
    // Update the count down every 1 second
    this.timerCount();
    this.interval = setInterval(() => this.timerCount(), 1000);
    document.querySelector('body').classList.add('pt-30');
    document.querySelector('body').classList.add('md:pt-20');
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    timerCount() {
      let now = new Date().getTime();
      let timeLeft = this.date - now;

      if (timeLeft > 0) {
        this.calcTime(timeLeft);
      } else {
        clearInterval(this.interval);
      }
    },

    calcTime(time) {
      this.days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((time % (1000 * 60)) / 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  @apply border-2 rounded bg-secondary text-primary;
  width: 40px;

  @screen md {
    @apply py-1;
    width: 55px;
  }

  &:not(:last-child) {
    margin-right: .5rem;
  }
}

.digit {
  @apply leading-none tracking-tight;
  font-size: 16px;

  @screen md {
    font-size: 24px;
  }
}

.format {
  @apply leading-none tracking-tight;
  font-size: 8px;

  @screen md {
    font-size: 10px;
  }
}

.label {
  @apply text-secondary;
  padding-top: .125rem;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;

  @screen md {
    font-size: .75rem;
  }
}
</style>
